import SchrootLootContractJSON from '../assets/contracts/SchrootLoot.json';
import SchrootContractJSON from '../assets/contracts/Schroot.json';
import ShellfurdsContractJSON from '../assets/contracts/Shellfurds.json';

const collections = [
  {
    id: '1',
    name: 'Schroot',
    contractAddress: '0xf7ba3f18FcB0aeB56D1340be8282e634FC2Cc05E',
    baseUrl:
      'https://api.schroot.io/json/',
    ipfs: false,
    abi: SchrootContractJSON,
    openSeaUrl:
      'https://opensea.io/assets/matic/0xf7ba3f18fcb0aeb56d1340be8282e634fc2cc05e/',
    bicoDomainName: process.env.REACT_APP_BICONOMY_DOMAIN_NAME_SCHROOT,
    oldBicoMethod: true,
    rewards: {
      daily: 40,
      weekly: 125,
      monthly: 1000,
      quarterly: 3750,
      semiAnnually: 9000,
    },
  },
  {
    id: '2',
    name: 'Shellfurds',
    contractAddress: '0x2666929AD3eeE9cd8862689D020773b3d4025BaB',
    baseUrl:
      'https://assets.indefinitelabs.io/metadata/shellfurds/jsons/',
    ipfs: false,
    abi: ShellfurdsContractJSON,
    openSeaUrl:
      'https://opensea.io/assets/matic/0xbd6d79a958078684e7508b99a7135eba8ddffc47/',
    bicoDomainName: process.env.REACT_APP_BICONOMY_DOMAIN_NAME_SHELLFURD,
    oldBicoMethod: false,
    rewards: {
      daily: 2,
      weekly: 5,
      monthly: 40,
      quarterly: 185,
      semiAnnually: 450,
    },
  },
  {
    id: '3',
    name: 'Schroot Loot',
    contractAddress: '0xbD6d79a958078684E7508b99a7135Eba8ddFFc47',
    baseUrl:
      'https://assets.indefinitelabs.io/metadata/schroot-loot/jsons/',
    ipfs: false,
    abi: SchrootLootContractJSON,
    openSeaUrl:
      'https://opensea.io/assets/matic/0xbd6d79a958078684e7508b99a7135eba8ddffc47/',
    bicoDomainName: process.env.REACT_APP_BICONOMY_DOMAIN_NAME_LOOT,
    oldBicoMethod: true,
    rewards: {
      daily: 0.25,
      weekly: 0.05,
      monthly: 4,
      quarterly: 18,
      semiAnnually: 45,
    },
  },
];

export const getCollectionById = (id) => collections.find((x) => x.id === id);

export const getCollectionByAddress = (address) =>
  collections.find((x) => x.contractAddress === address);

export const getCollectionByMetaUrl = (metaUrl) =>
  collections.find((x) => metaUrl.includes(x.baseUrl));

export default collections;
